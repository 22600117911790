import * as v from "valibot";

const debug =
  typeof window === "undefined" &&
  (process.env.DEV === "1" ||
    process.env.FRONTEND_ENV === "dev" ||
    !!process.env.FUNCTION_DEBUG_MODE);

export class CustomValiError<
  T extends
    | v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>
    | v.BaseSchemaAsync<unknown, unknown, v.BaseIssue<unknown>>,
> extends v.ValiError<T> {
  private schemaName: string;
  constructor(
    err: unknown,
    { schemaName, isDebug = debug }: { schemaName: string; isDebug?: boolean }
  ) {
    if (!err || typeof err !== "object" || !("name" in err) || err.name !== "ValiError") {
      throw new Error("Invalid error type: expected ValiError");
    }
    const error = err as v.ValiError<T>;
    super(error.issues);
    this.message = error.message;
    this.schemaName = schemaName;
    this.name = `${error.name}: ${schemaName}`;
    if (isDebug) {
      // biome-ignore lint/suspicious/noConsole: <explanation>
      console.error("\x1b[38;2;255;0;255m%s\x1b[0m", this.debugMessage);
    }
  }

  get debugMessage() {
    let message = "\n=== Valibot Validation Error ===\n";
    this.issues.forEach((issue, index) => {
      const pathString = issue.path?.map((p) => (p.key != null ? p.key : "(index)")).join(".");
      message += `[Issue #${index + 1}] ${this.schemaName}\n`;
      message += `- path:     ${pathString}\n`;
      message += `- message:  ${issue.message}\n`;
      message += `- expected: ${issue.expected}\n`;
      message += `- received: ${issue.received}\n`;
    });
    message += "=================================\n";
    return message;
  }
}
