import * as Sentry from "@sentry/remix";
import * as v from "valibot";
import { CustomValiError } from "../../../../packages/domain/src/utils/custom-vali-error";

/**
 * カスタムエラーをSentryに送信する
 */
export const vParse = <TSchema extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>>(
  schema: TSchema,
  input: unknown,
  config?: v.Config<v.InferIssue<TSchema>> & {
    captureError?: boolean;
  }
): v.InferOutput<TSchema> => {
  try {
    return v.parse(schema, input, config);
  } catch (error) {
    if (error instanceof v.ValiError) {
      Sentry.captureException(error, {
        extra: {
          schema,
          input,
        },
      });
      // biome-ignore lint/suspicious/noConsole: <explanation>
      console.error(new CustomValiError(error, { schemaName: "app/libs/valibot.ts" }));
    }
    return input;
  }
};
